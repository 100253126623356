<template>
  <v-card v-if="offer">
    <v-card-text>
      <v-form>
        <v-container id="editcontainer">
          <v-layout
            row
            wrap
          >
            <template v-if="!offer">
              loading...
            </template>
            <template v-else>
              <v-flex
                xs12
                md6
              >
                <v-text-field
                  ref="formTop"
                  v-model="offer.admin"
                  :disabled="true"
                  label="Analista"
                />
              </v-flex>

              <v-flex>
                <v-text-field
                  v-model="offer.applicants_count"
                  :disabled="true"
                  label="Número de Postulantes"
                  :error-messages="respErrors.applicants_count"
                />
              </v-flex>

              <v-flex xs8>
                <v-text-field
                  v-model="offer.state"
                  :disabled="true"
                  label="Estado"
                  :error-messages="respErrors.state"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="offer.position"
                  label="Cargo *"
                  :error-messages="respErrors.position"
                />
              </v-flex>

              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="offer.company"
                  label="Empresa *"
                  :disabled="offer.offer_owner == marketplace"
                />
              </v-flex>

              <v-flex
                xs12
                sm6
              >
                <AreaPicker
                  v-model="offer.area"
                  label="Área *"
                />
              </v-flex>

              <v-flex
                xs12
                sm6
              >
                <CityPicker
                  :key="offer.region"
                  v-model="offer.city"
                  :region="offer.region"
                  :disabled="!offer.region"
                  label="Seleccione ciudad o comuna"
                />
              </v-flex>

              <v-flex
                xs12
                sm4
              >
                <v-select
                  v-model="offer.contract_type"
                  label="Tipo de contrato *"
                  :items="contracts"
                />
              </v-flex>

              <v-flex
                xs12
                sm4
              >
                <v-text-field
                  v-model="offer.vacancies"
                  label="Vacantes *"
                />
              </v-flex>

              <v-flex
                xs12
                sm4
              >
                <StudiesPicker
                  v-model="offer.studies"
                  :country="offer.country_code"
                  prepend-icon="school"
                  label="Nivel de Estudios"
                />
              </v-flex>

              <v-flex
                xs12
                sm6
                md3
              >
                <v-dialog
                  ref="dialog"
                  v-model="datepicker_modal"
                  :return-value.sync="offer.published_date"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template slot="activator">
                    <v-text-field
                      v-model="offer.published_date"
                      label="Fecha de creación *"
                      prepend-icon="event"
                      readonly
                    />
                  </template>
                  <v-date-picker
                    v-model="offer.published_date"
                    scrollable
                  >
                    <v-spacer />
                    <v-btn
                      flat
                      color="primary"
                      @click="datepicker_modal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      flat
                      color="primary"
                      @click="$refs.dialog.save(offer.published_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>

              <v-flex
                xs12
                sm8
              >
                <PerkPicker
                  v-model="offer.perks"
                  label="Beneficios de la empresa"
                />
              </v-flex>

              <v-flex
                xs12
                sm4
              >
                <ConsultantPicker
                  v-model="offer.consultant"
                  label="Consultor"
                  :disabled="is_consultant"
                  :exclude-myself="!is_consultant"
                />
              </v-flex>

              <v-flex
                xs12
              >
                <v-label>Renta Ofrecida</v-label>
              </v-flex>
              <v-flex
                xs6
                sm4
              >
                <v-text-field
                  v-model="offer.offer_wage_min"
                  label="Desde *"
                />
              </v-flex>
              <v-flex
                xs6
                sm4
              >
                <v-text-field
                  v-model="offer.offer_wage_max"
                  label="Hasta *"
                />
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="offer.title"
                  label="Título de la oferta"
                  :error-messages="respErrors.title"
                  :counter="maxCharacters"
                  :maxlength="maxCharacters"
                />
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  v-model="offer.summary"
                  label="Resumen corto de la empresa"
                  counter="250"
                  :error-messages="respErrors.summary"
                />
              </v-flex>

              <v-flex xs12>
                <v-textarea
                  v-model="offer.objectives"
                  label="Objetivos"
                  outline
                />
              </v-flex>

              <v-flex xs12>
                <v-textarea
                  v-model="offer.description"
                  label="Funciones"
                  outline
                />
              </v-flex>

              <v-flex xs12>
                <v-textarea
                  v-model="offer.requirements"
                  label="Requisitos"
                  outline
                />
              </v-flex>
              <v-flex
                v-for="(question, index) in offer.questions"
                :key="`text-question-${index}`"
                xs12
              >
                <v-text-field
                  v-model="question.question"
                  :label="`Pregunta #${question.position +1}`"
                />
              </v-flex>
              <v-flex
                v-for="(question, index) in offer.range_questions"
                :key="`range-question-${index}`"
                xs12
              >
                <v-text-field
                  v-model="question.text"
                  :label="`Pregunta #${question.position + 1}`"
                />
                <v-radio-group
                  v-if="isYesNoQuestion(question)"
                  row
                >
                  <v-radio
                    v-for="(option, idx) in question.options"
                    :key="`range_questions_${index}_option_${idx}`"
                    :label="option.text"
                    :value="option.value"
                  />
                </v-radio-group>
                <v-slider
                  v-else-if="isLikertQuestion(question)"
                  v-model="question.value_likert"
                  :tick-labels="question.options.map(data => data.text)"
                  :max="question.options.length"
                  min="1"
                  step="1"
                  ticks="always"
                  tick-size="2"
                />
              </v-flex>
              <v-flex
                xs12
              />
              <v-flex
                v-if="offer.multi_choice_questions.length > 0"
                xs12
                class="mt-3"
              />
              <v-flex
                v-for="(rq, index) in offer.multi_choice_questions"
                :key="`multi_choice_questions-${index}`"
                xs12
              >
                <v-text-field
                  v-model="rq.text"
                  :label="`Pregunta #${rq.position + 1}`"
                />
                <div
                  v-for="(row, idoptions) in rq.multi_options"
                  :key="`multiple_question_${index}_multi_options_${idoptions}`"
                >
                  <v-flex xs6>
                    <v-layout>
                      <v-checkbox
                        v-model="row.expected_option"
                        hide-details
                        class="shrink mr-2"
                      />
                      <template v-if="row.pk">
                        <v-text-field
                          v-model="row.text"
                        />
                      </template>
                      <template v-else>
                        <v-text-field
                          v-model="row.text"
                          append-outer-icon="fal fa-minus-circle"
                          @click:append-outer="removeMultiChoiceOptionOldQuestion(index, idoptions)"
                        />
                      </template>
                    </v-layout>
                  </v-flex>
                </div>
                <v-btn
                  :large="$vuetify.breakpoint.mdAndUp"
                  outline
                  color="primary"
                  class="mb-5"
                  @click="addMultiChoiceOptionOldQuestion(index)"
                >
                  <span>Agregar nueva alternativa</span>
                  <v-icon
                    large
                    right
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-flex>
              <v-flex
                v-for="(question, index) in new_questions"
                :key="`new_questions-${index}`"
                xs12
              >
                <v-text-field
                  v-model="question.text"
                  :label="`Pregunta #${newPosition() + index + 1}`"
                  append-outer-icon="fas fa-times-circle"
                  color="red"
                  @click:append-outer="handleQuestionClear(new_questions, index)"
                />
                <v-radio-group
                  v-if="question.subtype === 'yesno'"
                  row
                >
                  <v-radio
                    v-for="(option, idx) in question.options"
                    :key="`range_questions_${index}_option_${idx}`"
                    :label="option.text"
                    :value="option.value"
                  />
                </v-radio-group>
                <v-slider
                  v-if="question.subtype === 'likert'"
                  v-model="question.value_likert"
                  :tick-labels="question.options.map(data => data.text)"
                  :max="question.options.length"
                  min="1"
                  step="1"
                  ticks="always"
                  tick-size="2"
                />
                <template v-if="question.type === 'multichoice'">
                  <v-layout>
                    <p
                      class="blue-grey--text text--darken-1"
                      style="font-size: 14px;"
                    >
                      Debes marcar al menos una opción de respuesta que quieres recibir,
                      los postulantes sólo verán la pregunta
                    </p>
                    <v-icon
                      medium
                      class="mb-3 ml-2"
                    >
                      fal fa-exclamation-circle
                    </v-icon>
                  </v-layout>
                  <v-flex
                    v-for="(row, idoptions) in question.multi_options"
                    :key="`${index}_multi_options_${idoptions}`"
                    sm6
                  >
                    <v-layout>
                      <v-checkbox
                        v-model="row.expected_option"
                        hide-details
                        class="shrink mr-2"
                      />
                      <v-text-field
                        v-model="row.text"
                        append-outer-icon="fal fa-minus-circle"
                        @click:append-outer="removeMultiChoiceOption(index, idoptions)"
                      />
                    </v-layout>
                  </v-flex>
                  <v-btn
                    :large="$vuetify.breakpoint.mdAndUp"
                    outline
                    color="primary"
                    class="mb-5"
                    @click="addMultiChoiceOption(index)"
                  >
                    <span>Agregar nueva alternativa</span>
                    <v-icon
                      large
                      right
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
              </v-flex>
              <v-flex xs12>
                <v-switch
                  v-model="offer.consider_sending_tests"
                  color="orange darken-3"
                  label="Enviar tests online (si se cumplen las condiciones)"
                />
              </v-flex>
            </template>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <template>
        <v-layout
          row
          align-left
          justify-left
          class="ml-4 mb-5"
        >
          <v-menu
            offset-y
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip
                :disabled="$vuetify.breakpoint.smAndDown"
                right
                color="white"
              >
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    color="accent"
                    :large="$vuetify.breakpoint.smAndUp"
                    dark
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                  >
                    Agregar nuevo tipo de pregunta
                    <v-icon
                      large
                      right
                      dark
                    >
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  <p
                    class="black--text"
                  >
                    Presiona acá para seleccionar el tipo de <br>
                    preguntas que realizarás a tus candidatos
                  </p>
                </span>
              </v-tooltip>
            </template>
            <v-list>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      block
                      color="white"
                      :disabled="offer.questions.length > 0 || offer.text_questions.length > 0"
                      @click="addTextQuestion"
                    >
                      <v-layout
                        justify-space-between
                      >
                        pregunta de texto
                        <v-icon
                          right
                          medium
                        >
                          mdi-chevron-right
                        </v-icon>
                      </v-layout>
                    </v-btn>
                  </div>
                </template>
                <span>Máximo 1 pregunta de texto</span>
              </v-tooltip>
              <v-btn
                block
                color="white"
                @click="addYesNoQuestion"
              >
                <v-layout
                  justify-space-between
                >
                  pregunta Si/No
                  <v-icon
                    right
                    medium
                  >
                    mdi-chevron-right
                  </v-icon>
                </v-layout>
              </v-btn>
              <v-btn
                block
                color="white"
                @click="addLikertQuestion"
              >
                <v-layout
                  justify-space-between
                >
                  pregunta Likert
                  <v-icon
                    right
                    medium
                  >
                    mdi-chevron-right
                  </v-icon>
                </v-layout>
              </v-btn>
              <v-btn
                block
                color="white"
                @click="addMultiChoiceQuestion"
              >
                <v-layout
                  justify-space-between
                >
                  Pregunta de selección múltiple
                  <v-icon
                    right
                    medium
                  >
                    mdi-chevron-right
                  </v-icon>
                </v-layout>
              </v-btn>
            </v-list>
          </v-menu>
        </v-layout>
      </template>
    </v-card-actions>
    <v-layout class="ml-4 mb-2">
      <template v-if="offer_is_open">
        <v-btn
          color="warning"
          @click.stop="tryToggleOfferState(closeEndpoint)"
        >
          <v-icon left>
            lock
          </v-icon>
          Finalizar oferta
        </v-btn>
        <v-dialog
          v-model="closeOfferDialog"
          max-width="400"
        >
          <v-card>
            <v-card-title
              class="headline primary white--text"
              primary-title
            >
              No hay ningún contratado para esta oferta
            </v-card-title>
            <v-card-text>
              Haz click en confirmar para cerrar de igual manera la oferta.
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-btn
                color="primary"
                flat
                @click="closeOfferDialog = false"
              >
                Cancelar
              </v-btn>
              <v-spacer />
              <v-btn
                color="warning"
                flat
                @click="closeOfferDialog = false; toggleOfferState(closeEndpoint);"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-else>
        <v-btn
          color="primary"
          @click="toggleOfferState(activateEndpoint)"
        >
          <v-icon left>
            lock_open
          </v-icon>
          Activar oferta
        </v-btn>
      </template>
    </v-layout>
    <v-layout
      class="ml-4 mb-2"
      align-content-space-between
    >
      <v-btn
        v-if="marketplace_pending_approval"
        color="warning"
        @click="openReject"
      >
        Rechazar Oferta
      </v-btn>
      <v-btn
        color="warning"
        @click="suspendOffer"
      >
        <v-icon left>
          mdi-cancel
        </v-icon>
        Suspender oferta
      </v-btn>
    </v-layout>
    <v-card-actions>
      <v-flex class="ml-4 mb-4">
        <v-btn
          color="primary"
          :disabled="missingOptions"
          class="mr-2"
          @click="submit"
        >
          <v-icon left>
            save
          </v-icon>
          Guardar cambios
        </v-btn>
        <slot name="close" />
      </v-flex>
    </v-card-actions>
  </v-card>
  <v-card v-else>
    <v-card-text>
      <v-icon color="primary">
        mdi-reload mdi-spin
      </v-icon>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';
import AreaPicker from '@/components/inputs/AreaPicker.vue';
import CityPicker from '@/components/inputs/CityPicker.vue';
import StudiesPicker from '@/components/inputs/StudiesPicker.vue';
import PerkPicker from '@/components/inputs/PerkPicker.vue';
import ConsultantPicker from '@/components/inputs/ConsultantPicker.vue';
import JWTConfigMixin from '../mixins/JWTConfigMixin.vue';

export default {
  name: 'EditOffer',
  components: {
    AreaPicker,
    CityPicker,
    StudiesPicker,
    PerkPicker,
    ConsultantPicker,
  },
  mixins: [JWTConfigMixin],
  props: {
    offerUuid: {
      type: String,
      required: true,
    },
    dialogToggle: {
      type: Boolean,
    },
  },
  data() {
    return {
      offer: null,
      submitting: false,
      datepicker_modal: false,
      respErrors: {},
      contracts: [],
      type_likert: 2,
      rejectConfirm: false,
      rejectRecommendations: '',
      marketplace: 2,
      closeOfferDialog: false,
      maxCharacters: 40,
      new_questions: [],
      options: [
        {
          position: 0,
          text: '1',
          value: 1,
        },
        {
          position: 1,
          text: '2',
          value: 2,
        },
        {
          position: 2,
          text: '3',
          value: 3,
        },
        {
          position: 3,
          text: '4',
          value: 4,
        },
        {
          position: 4,
          text: '5',
          value: 5,
        },
      ],
    };
  },
  computed: {
    activateEndpoint() {
      return this.$store.state.endpoints.offers.admin.activateOffer(this.offerUuid);
    },
    closeEndpoint() {
      return this.$store.state.endpoints.offers.admin.closeOffer(this.offerUuid);
    },
    missingOptions() {
      if (this.offer.multi_choice_questions) {
        const options = this.offer.multi_choice_questions.map(data => {
          const copy = { ...data };
          copy.multi_options = copy.multi_options.map(option => {
            const copyTwo = { ...option };
            return copyTwo.expected_option;
          });
          return copy.multi_options.includes(true);
        });
        return options.some(data => data === false);
      }
      return false;
    },
    is_consultant() {
      return this.offer.consultant_username === this.$store.state.adminName;
    },
    offer_is_open() {
      return this.offer && this.offer.state === 'activo';
    },
    offer_endpoint() {
      return this.$store.state.endpoints.offers.admin.get(this.offerUuid);
    },
    marketplace_pending_approval() {
      return this.offer && this.offer.state === 'pendiente' && this.offer.offer_owner === 2;
    },
    payload() {
      const res = { ...this.offer };
      res.new_questions = this.new_questions.map((question, index) => {
        const copy = { ...question };
        copy.position = this.newPosition() + index;
        return copy;
      });
      // properly map old-style text questions
      res.text_questions = res.text_questions.map((textQuestion, index) => {
        const textQuestionCopy = { ...textQuestion };
        const question = res.questions[index];
        textQuestionCopy.text = question.question;
        return textQuestionCopy;
      });
      return res;
    },
  },
  mounted() {
    this.$nextTick(() => {
      axios.get(this.$store.state.endpoints.offers.admin.contracts, this.jwtConfig)
        .then(resp => {
          this.contracts = resp.data;
          this.loadOffer();
        });
    });
  },
  methods: {
    addMultiChoiceOptionOldQuestion(index) {
      const pos = this.offer.multi_choice_questions[index].multi_options.map(data => data.position);
      const lastPosition = Math.max(...pos);
      this.offer.multi_choice_questions[index].multi_options.push({
        text: '',
        position: lastPosition + 1,
        expected_option: false,
      });
    },
    removeMultiChoiceOptionOldQuestion(index, idoptions) {
      this.offer.multi_choice_questions[index].multi_options.splice(idoptions, 1);
    },
    newPosition() {
      // Returns the position that the new question should have
      return this.offer.multi_choice_questions.length + this.offer.text_questions.length
      + this.offer.range_questions.length;
    },
    handleQuestionClear(data, index) {
      data.splice(index, 1);
    },
    addTextQuestion() {
      this.new_questions.push({
        type: 'text',
        position: 0,
        text: '',
      });
    },
    addYesNoQuestion() {
      this.new_questions.push({
        type: 'range',
        subtype: 'yesno',
        position: 0,
        text: '',
        options: [
          {
            position: 0,
            text: 'Si',
            value: 1,
          },
          {
            position: 1,
            text: 'No',
            value: 0,
          },
        ],
      });
    },
    addLikertQuestion() {
      this.new_questions.push({
        type: 'range',
        subtype: 'likert',
        position: 0,
        text: '',
        value_likert: 5,
        options: this.options,
        value_likert_index: 4,
      });
    },
    addMultiChoiceQuestion() {
      this.new_questions.push({
        type: 'multichoice',
        position: 0,
        text: '',
        multi_options: [
          {
            text: '',
            position: 0,
            expected_option: false,
          },
        ],
      });
    },
    addMultiChoiceOption(index) {
      const optionsPositions = this.new_questions[index].multi_options.map(data => data.position);
      const lastPosition = Math.max(...optionsPositions);
      this.new_questions[index].multi_options.push({
        text: '',
        position: lastPosition + 1,
        expected_option: false,
      });
    },
    removeMultiChoiceOption(index, idoptions) {
      this.new_questions[index].multi_options.splice(idoptions, 1);
    },
    isYesNoQuestion(question) {
      return question.options.length === 2 && question.options.find(d => d.text === 'Si') && question.options.find(d => d.text === 'No');
    },
    isLikertQuestion(question) {
      return question.question_type === this.type_likert;
    },
    loadOffer() {
      axios.get(this.offer_endpoint, this.jwtConfig)
        .then(response => {
          this.offer = response.data;
        });
    },
    tryToggleOfferState(endpoint) {
      if (!this.offer.has_hired_candidate) {
        this.closeOfferDialog = true;
      } else {
        this.toggleOfferState(endpoint);
      }
    },
    toggleOfferState(endpoint) {
      axios.post(endpoint, {}, this.jwtConfig)
        .then(resp => {
          this.$emit('editExit');
          this.loadOffer();
          if (resp.status !== 200) {
            this.$store.commit('adminSnackbarUp', `Estado de oferta cambiado | ${resp.data.multiposting_error}`);
          } else {
            this.$store.commit('adminSnackbarUp', 'Estado de oferta cambiado.');
          }
        });
    },
    openReject() {
      this.$emit('rejectConfirm', this.offerUuid);
    },
    suspendOffer() {
      const endpoint = this.$store.state.endpoints.offers.admin.suspendOffer(this.offerUuid);
      axios.post(endpoint, {}, this.jwtConfig)
        .then(resp => {
          this.$emit('editExit');
          this.loadOffer();
          if (resp.status !== 200) {
            this.$store.commit('adminSnackbarUp', ` Oferta suspendida. | ${resp.data.multiposting_error}`);
          } else {
            this.$store.commit('adminSnackbarUp', 'Oferta suspendida.');
          }
        });
    },
    submit() {
      this.submitting = true;
      axios.put(this.offer_endpoint, this.payload, this.jwtConfig)
        .then(() => {
          this.submitting = false;
          this.$emit('editExit');
          this.$store.commit('adminSnackbarUp', 'Oferta editada exitosamente.');
          this.new_questions = [];
          this.loadOffer();
        })
        .catch(err => {
          this.submitting = false;
          this.respErrors = err.response.data;
          this.$store.commit('adminSnackbarUp', 'Verificar que los datos estén correctos.');
          document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0;
        });
    },
  },
};
</script>
