<template>
  <v-select
    v-model="pickerValue"
    :items="filterConsultant"
    item-value="id"
    item-text="consultant_name"
    v-bind="$attrs"
    persistent-hint
  />
</template>
<script>
import axios from 'axios';
import JWTConfigMixin from '../mixins/JWTConfigMixin.vue';

export default {
  name: 'ConsultantPicker',
  mixins: [JWTConfigMixin],
  props: {
    value: {
      type: Number,
      required: true,
    },
    excludeMyself: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pickerValue: '',
      consultant: [],
    };
  },
  computed: {
    filterConsultant() {
      const res = [...this.consultant];
      if (this.excludeMyself) {
        return res.filter(
          user => user.consultant_email !== this.$store.state.adminName,
        );
      }
      return res;
    },
  },
  watch: {
    pickerValue() {
      this.$emit('input', this.pickerValue);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.pickerValue = this.value;
      this.loadConsultant();
    });
  },
  methods: {
    loadConsultant() {
      axios.get(this.$store.state.endpoints.offers.admin.getAdmins, this.jwtConfig)
        .then(response => {
          this.consultant = response.data;
          console.log('CONSULTANT', response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
  },
};
</script>
