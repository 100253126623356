<template>
  <v-container>
    <h1>¡Bienvenido!</h1>
    <br>
    <v-layout
      v-if="filterOffers.length > 0"
      row
      wrap
    >
      <h2>Recuerda que debes revisar las siguientes ofertas: </h2>
      <v-flex
        xs12
        class="mt-3"
      >
        <v-data-table
          :headers="headers"
          :items="filterOffers"
          :pagination.sync="pagination"
          class="elevation-3"
        >
          <template v-slot:headers="props">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                align="left"
                style="font-size: small; font-weight: bold"
              >
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template
            slot="items"
            slot-scope="props"
          >
            <td>{{ props.item.published_date }}</td>
            <td>{{ props.item.company }}</td>
            <td>
              <ul>
                <li v-if="props.item.active_long_time">
                  Se debe cerrar suspender o republicar oferta.
                </li>
                <li v-if="props.item.pending_long_time">
                  Favor revisar oferta.
                </li>
                <li v-if="props.item.few_candidates">
                  Promociona o comparte la oferta para llegar a más candidatos.
                </li>
                <li v-if="props.item.candidates_in_process">
                  Favor cambiar de estado los candidatos.
                </li>
              </ul>
            </td>
            <td>{{ props.item.position }}</td>
            <td>{{ props.item.admin }}</td>
            <td>
              <v-btn
                :to="{name: 'admin:offer_applicants',
                      params: {uuid: props.item.uuid }}"
              >
                <v-icon left>
                  people
                </v-icon>
                {{ props.item.applicants_count }}
              </v-btn>
            </td>
            <td>
              <v-tooltip
                bottom
                nudge-right="15"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    outlined
                    @click="showOfferDialog(props.item)"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar oferta</span>
              </v-tooltip>
              <v-tooltip
                bottom
                nudge-right="15"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    :to="{name: 'admin:new_offer',
                          params: {offer: props.item }}"
                    icon
                    outlined
                    v-on="on"
                  >
                    <v-icon>
                      mdi-content-duplicate
                    </v-icon>
                  </v-btn>
                </template>
                <span>Duplicar oferta</span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:no-results>
            No se encontraron resultados.
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout v-else>
      <h2>No hay ofertas que requieran revisión C:</h2>
    </v-layout>
    <v-dialog
      v-if="edit_offer_uuid"
      id="edit-dialog"
      v-model="show_edit_dialog"
      max-width="900px"
      persistent
    >
      <EditOffer
        :key="edit_offer_uuid"
        :offer-uuid="edit_offer_uuid"
        @editExit="loadOffers()"
        @rejectConfirm="prepareReject"
      >
        <template slot="close">
          <v-btn @click="show_edit_dialog = false">
            Cancelar
          </v-btn>
        </template>
      </EditOffer>
    </v-dialog>

    <v-dialog
      id="rejectConfirm"
      v-model="rejectConfirm"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          Rechazar oferta Marketplace
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="rejectRecommendations"
            outline
            name="input-7-4"
            label="Recomendaciones para el cliente"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="warning"
            @click="rejectOffer"
          >
            Rechazar offerta
          </v-btn>
          <v-btn
            color="warning"
            @click="resetRejection"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import EditOffer from '@/components/offers/EditOffer.vue';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'OfferWarnings',
  components: {
    EditOffer,
  },
  filters: {
    humanDate(dateStr) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateStr).toLocaleDateString('es-CL', options);
    },
  },
  mixins: [JWTConfigMixin],
  data() {
    return {
      rejectRecommendations: '',
      rejectingOffer: '',
      rejectConfirm: false,
      stateItems: [
        { text: 'Activo', value: 'activo' },
        { text: 'Cerrado', value: 'cerrado' },
        { text: 'Suspendido', value: 'suspendido' },
      ],
      edit_offer_uuid: '',
      show_edit_dialog: false,
      dialog_title: '',
      headers: [
        {
          text: 'Fecha Creación',
          value: 'created_date',
        },
        {
          text: 'Empresa',
          value: 'company',
        },
        {
          text: 'Advertencias',
          value: [
            'active_long_time',
            'pending_long_time',
            'few_candidates',
            'candidates_in_process',
          ],
        },
        {
          text: 'Cargo',
          value: 'position',
        },
        {
          text: 'Analista',
          value: 'admin',
        },
        {
          text: 'Postulantes Internos',
          value: 'applicantsCount',
        },
        {
          text: 'Acciones',
        },
      ],
      offers: [],
      pagination: {
        descending: true,
        rowsPerPage: 10,
        page: 1,
      },
    };
  },
  computed: {
    filterOffers() {
      const listOffer = this.offers
        .filter(offer => offer.active_long_time
        || offer.pending_long_time
        || offer.few_candidates
        || offer.candidates_in_process);
      return listOffer;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadOffers();
    });
  },
  methods: {
    prepareReject(uuid) {
      this.rejectConfirm = true;
      this.rejectingOffer = uuid;
      console.log('emitted');
    },
    rejectOffer() {
      const payload = {
        offer: this.rejectingOffer,
        recommendations: this.rejectRecommendations,
      };
      const endpoint = this.$store.state.endpoints.offers.admin.rejectOffer(this.rejectingOffer);
      axios.post(endpoint, payload, this.jwtConfig)
        .then(resp => {
          if (resp.status === 200) {
            this.resetRejection();
            this.loadOffers();
            this.$store.commit('adminSnackbarUp', 'Oferta rechazada.');
          } else {
            this.$store.commit('adminSnackbarUp', 'Error al rechazar la oferta.');
          }
        });
    },
    resetRejection() {
      this.rejectRecommendations = '';
      this.rejectConfirm = false;
    },
    loadOffers() {
      this.show_edit_dialog = false;
      this.$store.commit('setLoading');
      axios.get(this.$store.state.endpoints.offers.admin.listHome, this.jwtConfig)
        .then(response => {
          this.offers = response.data;
          console.log('WARNING', this.filterOffers);
          this.$store.commit('unsetLoading');
        });
    },
    showOfferDialog(offer) {
      this.edit_offer_uuid = offer.uuid;
      this.dialog_title = offer.position;
      this.show_edit_dialog = true;
    },
  },
};
</script>
