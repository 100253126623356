<template>
  <OfferWarnings />
</template>

<script>
import OfferWarnings from './OfferWarnings.vue';

export default {
  name: 'Home',
  components: {
    OfferWarnings,
  },
};
</script>
